import { useEffect, useState } from "react";
import { useSupport } from "../../contexts/SupportContext";
import TicketListPagination from "../table/TablePagination";
import TicketsTable from "./TicketsTable";
import Loader from "../loading/Loader";

const TicketList: React.FC = () => {
  const { tickets, totalPages, hasPreviousPage, hasNextPage, loading, fetchTickets } = useSupport();

  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchTickets(page);
  }, [page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <div className="w-full border rounded-md border-gorgonzola-blue-50">
      <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
        <div className="flex flex-col justify-between w-full gap-2.5 px-4 py-6 md:items-center md:flex-row md:gap-0">
          <h2 className="font-bold text-closed-shutter-300">Support Ticket History</h2>
          <span className="text-xs text-closed-shutter-300/70">List of your sent tickets</span>
        </div>

        {loading ? (
          <div className="flex justify-center w-full p-6">
            <Loader />
          </div>
        ) : tickets.length > 0 ? (
          <>
            <TicketsTable tickets={tickets} />

            <div className="justify-center hidden md:flex">
              <TicketListPagination currentPage={page} totalPages={totalPages} hasPreviousPage={hasPreviousPage} hasNextPage={hasNextPage} onPageChange={handlePageChange} />
            </div>
          </>
        ) : (
          <div className="p-6 text-center">
            <p className="text-closed-shutter-300">No tickets found</p>
            <p className="mt-2 text-xs text-closed-shutter-200">You haven't submitted any support tickets yet. If you need assistance, please create a new ticket.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketList;
