import React from "react";
import Table from "../table/Table";
import { TableColumn } from "../../utilities/types/support/types";
import { DocumentUpload } from "iconsax-react";
import StatusBadge from "../table/StatusBadge";
import TablePagination from "../table/TablePagination";
import { Link } from "react-router-dom";
import { formatDate } from "../../utilities/helper/dateUtils";

interface Payment {
  id: number;
  hash_id: string;
  label: string;
  pay_operator: string;
  pay_operator_logo: string;
  status: string;
  amount: number;
  product_type: string;
  product: {
    id: number;
    name: string;
    price: number;
  } | null;
  last4_card_number: string;
  created_at: number;
}

interface PaymentsTableProps {
  data: {
    data: Payment[];
    current_page: number;
    last_page: number;
  };
  onPageChange: (newPage: number) => void;
}

const PaymentsTable: React.FC<PaymentsTableProps> = ({ data, onPageChange }) => {
  const columns: TableColumn<Payment>[] = [
    {
      key: "id",
      header: "Invoice",
      render: (value, item) => (
        <Link to={`/dashboard/payment/${item.hash_id || null}`} className="flex items-center gap-2 text-closed-shutter-300 hover:text-gorgonzola-blue-300">
          <span>#{value}</span>
          <DocumentUpload className="size-5" variant="Broken" />
        </Link>
      ),
    },
    {
      key: "pay_operator",
      header: "P.Method",
      render: (value, item) => (
        <div className="flex items-center gap-2">
          <img src={item.pay_operator_logo} alt={value} className="w-8 h-6" />
          {item.last4_card_number ? <span>•••• {item.last4_card_number}</span> : null}
        </div>
      ),
    },
    {
      key: "amount",
      header: "Amount",
      render: (value) => <span className="font-bold">${value.toFixed(2)}</span>,
    },
    {
      key: "status",
      header: "Status",
      render: (value) => (
        <div className="flex w-full">
          <StatusBadge statusText={value === "paid" ? "Success" : value === "failed" ? "Error" : "Pending"} isSuccess={value === "paid" ? true : value === "failed" ? false : null} />
        </div>
      ),
    },
    {
      key: "created_at",
      header: "Date",
      render: (value: number) => formatDate(new Date(value * 1000).toLocaleDateString()),
    },
    {
      key: "created_time",
      header: "Time",
      render: (value: number) => new Date(value * 1000).toLocaleTimeString(),
    },
  ];

  return (
    <div className="w-full border rounded-md border-gorgonzola-blue-50">
      <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
        <div className="flex items-center justify-between w-full px-4 py-6">
          <h2 className="font-bold text-closed-shutter-300">Your Payments</h2>
          <span className="text-xs text-closed-shutter-300/70">List of your payments</span>
        </div>

        {data && data.data && data.data.length > 0 ? (
          <>
            <Table data={data.data} columns={columns} />
            <TablePagination
              currentPage={data.current_page}
              totalPages={data.last_page}
              onPageChange={onPageChange}
              hasPreviousPage={data.current_page > 1}
              hasNextPage={data.current_page < data.last_page}
            />
          </>
        ) : (
          <div className="p-6 text-center">
            <p className="text-closed-shutter-300">No payment data available</p>
            <p className="mt-2 text-xs text-closed-shutter-200">Your payment history will appear here once you make transactions.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentsTable;
