import { useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { SidebarContext } from "../../contexts/SidebarContext";
import { SidebarLeft } from "iconsax-react";
import { Link } from "react-router-dom";

const NavbarLeftSide = () => {
  const { isLogoOpen, toggleLogo } = useContext(SidebarContext)!;

  return (
    <div className={`relative flex items-center justify-between gap-2 overflow-hidden ${isLogoOpen ? "w-fit" : "w-[20%] max-w-[17rem]"} `}>
      <Link to={"/dashboard"} className="z-30 min-w-fit">
        <img src="/assets/svg/main_logo.svg" alt="Main Logo" />
      </Link>

      <AnimatePresence>
        <motion.div className={`flex items-center justify-between w-full `} initial={{ x: -100, opacity: 0 }} animate={{ x: isLogoOpen ? -100 : 0, opacity: 1 }} transition={{ duration: 0.6 }}>
          <motion.img
            src="/assets/svg/logo_property.svg"
            alt="Main Logo"
            initial={{ opacity: 1 }}
            animate={{ opacity: isLogoOpen ? 0 : 1 }}
            transition={{ duration: 0.6 }}
          />
          <motion.div
            onClick={toggleLogo}
            initial={{ rotate: 180 }}
            animate={{ rotate: isLogoOpen ? 180 : 0 }}
            transition={{ duration: 0.6 }}
            className="p-2 rounded-full cursor-pointer size-10 hover:bg-gorgonzola-blue-100 duration-600"
          >
            <motion.div initial={{ x: 0 }} animate={{ x: 0 }} whileHover={{ x: [0, -3, 3, -3, 3, -3, 0] }} transition={{ duration: 2, delay: 0.3 }}>
              <SidebarLeft variant="Broken" className="size-6 text-closed-shutter-300" />
            </motion.div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default NavbarLeftSide;