import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { SidebarProvider } from "../../contexts/SidebarContext";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { Outlet } from "react-router-dom";

const DashboardTemplate = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const mainRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const mainElement = mainRef.current;
    if (!mainElement) return;

    mainElement.scrollTop = 0;

    const handleScroll = () => {
      setIsScrolled(mainElement.scrollTop > 0);
    };

    mainElement.addEventListener("scroll", handleScroll);

    return () => mainElement.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <SidebarProvider>
      <div className="relative flex flex-col gap-10 h-dvh">
        <Navbar />
        <div className="relative flex flex-1 gap-6 px-5 overflow-hidden md:px-8 lg:px-10">
          <Sidebar />

          <main ref={mainRef} className="flex-1 overflow-y-auto rounded-t-xl hide_scroll">
            <div className="z-20 pb-10">
              <div className="relative bg-white rounded-xl">
                <div className={`sticky inset-0 z-20`}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: isScrolled ? 1 : 0,
                    }}
                    transition={{
                      duration: 0.3,
                      ease: "easeInOut",
                    }}
                    className={`absolute inset-0 w-full h-4 z-20`}
                    style={{
                      background: `linear-gradient(
                          180deg,
                          rgba(255,255,255,1) 0%,
                          rgba(255,255,255,0.75) 20%,
                          rgba(255,255,255,0.50) 40%,
                          rgba(255,255,255,0.25) 60%,
                          rgba(255,255,255,0.10) 80%,
                          rgba(255,255,255,0.0) 100%
                        )`,
                    }}
                  />
                </div>

                <div>
                  <Outlet />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default DashboardTemplate;