import React from "react";
import TicketCreate from "./TicketCreate";
import TicketList from "./TicketList";
import { SupportProvider } from "../../contexts/SupportContext";

const Support: React.FC = () => {
  return (
    <SupportProvider>
      <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
        <div className="flex flex-col justify-between w-full gap-2.5 p-6 md:gap-0 md:flex-row">
          <h2 className="font-bold text-closed-shutter-300">Support</h2>

          <div className="flex items-center gap-2">
            <img src="/assets/image/get-help/header-gif.gif" alt="Header Gif" className="size-6" />
            <span className="text-xs text-closed-shutter-300/70">Our support staff are available 24/7</span>
          </div>
        </div>

        <div className="flex flex-col gap-6 px-3 py-6 md:px-6">
          <TicketCreate />
          <TicketList />
        </div>
      </div>
    </SupportProvider>
  );
};

export default Support;
